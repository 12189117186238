@font-face {
    font-family: "flaticon";
    src: url("./flaticon.ttf?d931e6694162dafd780da1e421534c9f") format("truetype"),
url("./flaticon.woff?d931e6694162dafd780da1e421534c9f") format("woff"),
url("./flaticon.woff2?d931e6694162dafd780da1e421534c9f") format("woff2"),
url("./flaticon.eot?d931e6694162dafd780da1e421534c9f#iefix") format("embedded-opentype"),
url("./flaticon.svg?d931e6694162dafd780da1e421534c9f#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-user:before {
    content: "\f101";
}
.flaticon-shopping-bag:before {
    content: "\f102";
}
.flaticon-search:before {
    content: "\f103";
}
.flaticon-arrow:before {
    content: "\f104";
}
.flaticon-cardiogram:before {
    content: "\f105";
}
.flaticon-strategy:before {
    content: "\f106";
}
.flaticon-yoga:before {
    content: "\f107";
}
.flaticon-eye:before {
    content: "\f108";
}
.flaticon-clock:before {
    content: "\f109";
}
.flaticon-recipe:before {
    content: "\f10a";
}
.flaticon-yoga-1:before {
    content: "\f10b";
}
.flaticon-stretch:before {
    content: "\f10c";
}
.flaticon-dumbbell:before {
    content: "\f10d";
}
.flaticon-next:before {
    content: "\f10e";
}
.flaticon-star:before {
    content: "\f10f";
}
.flaticon-star-1:before {
    content: "\f110";
}
.flaticon-newsletter:before {
    content: "\f111";
}
.flaticon-down-arrow:before {
    content: "\f112";
}
.flaticon-chevron:before {
    content: "\f113";
}
.flaticon-back:before {
    content: "\f114";
}
.flaticon-lock:before {
    content: "\f115";
}
.flaticon-promotion:before {
    content: "\f116";
}
.flaticon-success:before {
    content: "\f117";
}
.flaticon-full-screen:before {
    content: "\f118";
}
.flaticon-volume:before {
    content: "\f119";
}
.flaticon-check:before {
    content: "\f11a";
}
.flaticon-restaurant:before {
    content: "\f11b";
}
.flaticon-vegetarian:before {
    content: "\f11c";
}
.flaticon-salad:before {
    content: "\f11d";
}
.flaticon-vegan:before {
    content: "\f11e";
}
.flaticon-gluten-free:before {
    content: "\f11f";
}
.flaticon-no-milk:before {
    content: "\f120";
}
.flaticon-peanut-free:before {
    content: "\f121";
}
.flaticon-peanut-free-1:before {
    content: "\f122";
}
.flaticon-restaurant-1:before {
    content: "\f123";
}
.flaticon-next-1:before {
    content: "\f124";
}
