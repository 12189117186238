@font-face {
  font-family: Garacie;
  src: url(./UI/Garacie/Garacie.ttf);
}

@font-face {
  font-family: Commuters-Regular;
  src: url(./UI/Commuters/CommutersSans-Regular.otf);
}

@font-face {
  font-family: Commuters-Bold;
  src: url(./UI/Commuters/CommutersSans-SemiBold.otf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Commuters-Regular';
  letter-spacing: 0.02em;
}
a{
  text-decoration: none;
  color:#111;
}

body {
  margin: 0;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */